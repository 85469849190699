import React, { useState, Fragment } from 'react'
import './FinanceForm.scss'
import IndividualFormFields from './IndividualFormFields'
import CoAppFormFields from './CoAppFormFields'
import useDataLayer from '../../hooks/useDataLayer';
import axios from 'axios'

export default (props) => {
    const [formStatus, updateFormStatus] = useState({'Submitted': false, 'Loading': false, 'Success': false, 'Failed': false})
    const [individualForm, toggleFormType] = useState(true)
    const { title, text, disclaimer, buttonText } = props.data
    const { leadURL, site_id, pageSource, pageURL } = props
    const pushToDataLayer = useDataLayer()

    const submitForm = async (e) => {
        e.preventDefault()

        updateFormStatus({ 'Submitted': true, 'Loading': true, 'Success': false, 'Failed': false})
        document.getElementById('component__container').classList.toggle('successScale')

        let data = Object.fromEntries(new FormData(e.target).entries());
        let postData = {siteId: site_id, dealerID: "", pageSource, pageURL, lead: {...data}, AppType: individualForm ? 'Individual Finance Application' : 'Co-Applicant Finance Application'}

        console.log(postData)

        try {
            await axios.post(leadURL, JSON.stringify(postData))
            .then((res) => {
                if (res.status === 200) {
                    updateFormStatus({'Submitted': true, 'Failed': false, 'Loading': false, 'Success': true})
                    document.getElementById('loadingIcon').classList.toggle('load-complete')
                    document.getElementById('checkIcon').classList.toggle('checkmark')
                    pushToDataLayer("form-submission", res)
                    pushToDataLayer("finance", res)
                }
            })
        } catch (err) {
            updateFormStatus({...formStatus, 'Loading': false, 'Failed': true})
            alert(err)
        }
    }
 

    return (
        <div id="component__container" className="form__container">

            <div className="form__text">
                <h1>{title}</h1>
                <p>{text}</p>
                <button onClick={() => toggleFormType(!individualForm)}>{individualForm ? 'Applying With A Co-Applicant' : 'Applying With No Co-Applicant'}</button>
                <p className="button__disclaimer"><i>Switching application type while filled out will erase your data</i></p>
            </div>

            <div className="form__content">
                {!formStatus.Submitted ?
                    <Fragment>
                    <form onSubmit={submitForm}>
                        {individualForm ? <IndividualFormFields/> : <CoAppFormFields/>}
                        <div className="input__container">
                            <label>How would you like us to contact you?</label>
                            <div className="input__field checkbox">
                                <label><input name="ContactByCall" type="checkbox" value="Yes" /> Call</label>
                                <label><input name="ContactByText" type="checkbox" value="Yes" /> Text</label>
                                <label><input name="ContactByEmail" type="checkbox" value="Yes" /> Email</label>
                            </div>
                            <div className="terms">
                                <input type="checkbox" name="terms" required/> I agree to give permission for the above applicant(s) Consumer Credit Report(s) to be obtained, at no cost to me, in order to help determine the types and extent of financing which are available*
                            </div>
                        </div>
                        <button type="submit">{buttonText}</button>
                    </form>
                    <div className="disclaimer__container">{disclaimer}</div>
                </Fragment>
                :
                <div className="onSubmission">
                    <div id="loadingIcon" className="circle-loader">
                        <div id="checkIcon" className="draw"></div>
                    </div>
                    {formStatus.Success ?
                    <Fragment>
                        <h2>Thank you!</h2>
                        <p>Your application has been successfully submitted.</p>
                    </Fragment>
                    : ''}
                </div>
                }
            </div>

        </div>
    )
}